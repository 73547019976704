import { ArrowUpIcon, XIcon } from '@heroicons/react/solid';
import { useEffect, useState } from 'react';
import EssayCard from '../../Cards/essayCard';
import EditIcon from '../../Icons/edit';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import PinIcon from '../../Icons/pin';
import ThreadCard from '../../Cards/thread';
import { useRouter } from 'next/router';
import ThreadReader from '../../NewModals/threadReader';
import CollectionSocialBlogCard from './collection';

export default function ThreadSocialBlogCard({
  removeCard,
  openThreadSelector,
  threadID,
  userMatch,
  edit,
  creatorID,
  creatorSlug,
  creatorMeta,
  socialBlog = false,
  border,
}) {
  const [activeThread, setActiveThread] = useState();
  const [loading, setLoading] = useState(true);
  const [threadModalOpen, setThreadModalOpen] = useState(false);

  const router = useRouter();

  useEffect(() => {
    creatorID && creatorSlug && threadID ? getActiveThread() : null;
  }, [threadID]);

  function getActiveThread() {
    const ref = firebase
      .database()
      .ref('gallery/' + creatorSlug)
      .child(creatorID)
      .child('threads')
      .child(threadID);
    ref
      .once('value')
      .then(function (snapshot) {
        if (snapshot.exists()) {
          setActiveThread(snapshot);
        } else {
          //thread may have been migrated to /typeshares so find it there
          const ref = firebase
            .database()
            .ref('gallery/' + creatorSlug)
            .child(creatorID)
            .child('typeshares')
            .child(threadID);
          ref
            .once('value')
            .then(function (snapshot) {
              if (snapshot.exists()) {
                setActiveThread(snapshot);
              }
            })
            .then(() => {
              setLoading(false);
            });
        }
      })
      .then(() => {
        setLoading(false);
      });
  }

  const threadClick = (event) => {
    event.stopPropagation();
    openThreadSelector();
  };
  const close = (event) => {
    event.stopPropagation();
    removeCard();
  };
  const open = (event) => {
    event.stopPropagation();
    // setThreadModalOpen(true)
    activeThread.hasChild('tweetArray')
      ? router.push({
          pathname: '/[slug]/threads/[thread]',
          query: { slug: creatorSlug, thread: threadID },
        })
      : router.push({
          pathname: '/[slug]/posts/[post]',
          query: { slug: creatorSlug, post: threadID },
        });
  };

  const formatTS = (ts) => {
    if (ts) {
      var format = {
        id: ts.key,
        val: ts.val(),
      };
      return format;
    } else {
      return null;
    }
  };

  return (
    <div
      className={`group relative w-full ${
        socialBlog ? 'h-full' : 'flex self-stretch'
      } `}
    >
      {threadID !== null && !loading ? (
        <ThreadReader
          threadID={threadID}
          threadCreatorID={creatorID}
          threadCreatorSlug={creatorSlug}
          setThreadModalOpen={setThreadModalOpen}
          threadModalOpen={threadModalOpen}
          creatorMeta={creatorMeta}
        />
      ) : null}

      <div
        className={`${
          edit ? 'flex' : 'hidden'
        } absolute top-2 right-2 z-10 flex flex-row items-center justify-center space-x-2`}
      >
        <button
          onMouseDown={(event) => open(event)}
          onTouchStart={(event) => open(event)}
          className={` ${
            threadID !== null ? 'flex' : 'hidden'
          } rounded-full bg-gray-100 p-2 opacity-0 ring-gray-200 transition-all duration-200 hover:ring-4 group-hover:opacity-100`}
        >
          <ArrowUpIcon
            className={'h-4 w-4 rotate-45 transform text-gray-600'}
          />
        </button>
        <button
          onMouseDown={(event) => close(event)}
          onTouchStart={(event) => close(event)}
          className={` rounded-full bg-gray-100 p-2 text-red-500 opacity-0  ring-red-100 transition-all duration-200 hover:ring-4 group-hover:opacity-100`}
        >
          <XIcon className={'h-4 w-4'} />
        </button>
      </div>

      {threadID !== null && !loading && activeThread !== undefined ? (
        <div
          className={`w-full ${socialBlog ? 'h-full' : null} ${
            edit
              ? null
              : 'transform duration-200 hover:scale-105 active:scale-100'
          }`}
        >
          {/*                 <ThreadCard border={border} threadID={activeThread.key} userMatch={userMatch} edit={edit} creatorID={creatorID} creatorSlug={creatorSlug} creatorMeta={creatorMeta} click={() => edit ? null : router.push({ pathname: "/[slug]/threads/[thread]", query: { slug: creatorSlug, thread: threadID } })} />
           */}
          <EssayCard
            border={border}
            creatorSlug={creatorSlug}
            creatorID={creatorID}
            preview={true}
            socialBlog={true}
            typeshare={formatTS(activeThread)}
            edit={edit}
            click={() =>
              edit
                ? null
                : activeThread.hasChild('tweetArray')
                ? router.push({
                    pathname: '/[slug]/threads/[thread]',
                    query: { slug: creatorSlug, thread: threadID },
                  })
                : router.push({
                    pathname: '/[slug]/posts/[post]',
                    query: { slug: creatorSlug, post: threadID },
                  })
            }
          />
        </div>
      ) : !edit ? null : (
        <div
          style={{ boxShadow: '0px 4px 24px 0px #0000000D' }}
          className={` ${
            edit
              ? 'cursor-move'
              : 'transform cursor-pointer duration-200 hover:scale-105 active:scale-100'
          } group relative flex h-full w-full items-center justify-center overflow-hidden  rounded-3xl border border-dashed border-gray-300 bg-gray-100`}
        >
          <button
            onMouseDown={(event) => threadClick(event)}
            onTouchStart={(event) => threadClick(event)}
            className={`${
              threadID === null || activeThread === undefined
                ? 'flex'
                : 'hidden'
            } transform cursor-pointer rounded-full bg-gray-800 py-2 px-3 text-sm font-medium text-white backdrop-blur-xl  backdrop-filter duration-200 hover:scale-105 active:scale-100`}
          >
            <p>Select Thread</p>
          </button>
        </div>
      )}
    </div>
  );
}
